<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5 auth-bg">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="6" class="d-flex align-items-center p-5">
        <b-col rounded sm="8" md="6" lg="12" class="p-xl-5 auth-bg rounded">
          <b-card-title class="mb-1 text-center forgot-text">
            Forgot Passaword
          </b-card-title>
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img fluid :src="resetImgUrl" alt="Reset password V2" />
          </div>
          <b-card-text class="mb-2 text-muted py-5 text-center">
            A log in link has been sent to your mail. Please use that mail to
            direct log in to the system
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group class="d-flex justify-content-center mt-3">
                <b-button
                  type="submit"
                  class="btn btn-color rounded"
                  >Reset Password</b-button
                >
              </b-form-group>
            </b-form>
          </validation-observer>

          <!-- <p class="text-center mt-2 text-muted">
            <span>or</span>
          </p>
          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-register' }">
              <span class="sign-in-color">&nbsp;Create A New Account </span>
            </b-link>
          </p> -->
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from "vee-validate";

import store from "@/store/index";
import { toast } from "@/utils/toast";
import { email, required } from "@validations";
import {
BButton, BCardText, BCardTitle, BCol, BForm,
BFormGroup,
BFormInput, BImg, BLink, BRow
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/logo/logo-main.png"),
      resetPasswordImage: require("@/assets/images/elements/laptop.png"),
      // validation
      required,
      email,
    };
  },
  created() {
    if (this.$store.state.auth.passwordResetMail) {
      this.$router.push("/forgot-password");
    }
  },
  computed: {
    resetImgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties

        this.resetPasswordImage = require("@/assets/images/elements/laptop.png");
        return this.resetPasswordImage;
      }
      return this.resetPasswordImage;
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/logo/logo-main.png");

        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.$router.push("/reset-password");
          toast("Success","EditIcon","success", "Password reset link has been sent to your mail")
        }
      }).catch((error) => {
        toast("Error","AlertTriangleIcon","error", "Please enter a valid email")
        console.log(error);
      })
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.forgot-text {
  background: -webkit-linear-gradient(#01185e, #264296, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 53px;
}
.sign-in-color {
  background: -webkit-linear-gradient(#01185e, #264296, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  line-height: 53px;
}
.btn-color {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}
</style>
